import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import headerImg2 from "../assets/img/gary_chart (3).png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ListGroup from 'react-bootstrap/ListGroup';

/*

      <li>Contract audits show no warnings</li>
      */

export const OriginStory = () => {

  return (
    <section className="origin" id="tokenomics">
      <Container>
        <Row>
          <Col>
        <h2>The Origin Story</h2>
        <img src={headerImg2} alt="Header Img"/>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
