import { Container, Row, Col } from "react-bootstrap";
import logo from '../assets/img/logo.svg';
import headerImg from "../assets/img/the_hand_cropped.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {

  return (
    <section className="banner" id="home">
      <Container>

      <Row className="aligh-items-center">
        <Col xs={14} md={8} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={10} md={4} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
              
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1>{`the market showing its hand`}</h1>
                
                <span className="tagline">The Latest Meme in Crypto Twitter</span>
                  <p>
                  The SEC hack revealed how the market would react to the ETF approval, ultimately showing its hand.
                  </p>
                  <p>
                  This became a meme in which every price move was described by influencers as "the market showing its hand".
                  </p>
                  <p>
                  The result is a market with many hands.
                  </p>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
