import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import headerImg2 from "../assets/img/gary_chart (3).png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ListGroup from 'react-bootstrap/ListGroup';

/*

      <li>Contract audits show no warnings</li>
      */

export const Tokenomics = () => {

  return (
    <section className="Tokenomics" id="tokenomics">
      <Container>
        <div>
              <h2>Tokenomics</h2>
                  <p className="tagline2">
                <ul>
              <li>Token supply: 5,555,555,555,555 </li>
              <li>Circulating supply: 5,555,555,555,555 </li>
              <li>Ownership renounced</li>
              <li>No taxes</li>
              <li>No presale</li>
              <li>No inflation</li>
              <li>Contract verified</li>
              </ul>
                  </p>
              </div>
      </Container>
    </section>
  )
}
