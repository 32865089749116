import { Container } from "react-bootstrap";
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import navIcon4 from '../assets/img/nav-icon4.svg';

export const Footer = () => {
  return (
    
    <footer className="footer">
      <Container>
      <span className="navbar-text">
              <div className="social-icon">
                <a href="https://twitter.com/themarketshand"><img src={navIcon1} alt="" /></a>
                <a href="https://etherscan.io/token/0x90bd9b21681da86d4907477de15a5648699b8e91"><img src={navIcon2} alt="" /></a>
                <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xbf4d6ec47db0937663d9ba5743c2047da92042cb"><img src={navIcon3} alt="" /></a>
                <a href="https://app.uniswap.org/swap?outputCurrency=0x90bd9b21681da86d4907477de15a5648699b8e91"><img src={navIcon4} alt="" /></a>
              </div>
            </span>
            <p>Copyright 2024. All Rights Reserved</p>
            
            
      </Container>
    </footer>
  )
}
